<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <div class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            Admin Panel
          </h2>
        </div>

        <b-card-title class="text-center mb-1">
          Welcome 👋
        </b-card-title>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="onSubmit"
          >
            <!-- email -->
            <b-form-group
              label-for="username"
              label="Username"
            >
              <validation-provider
                #default="{ errors }"
                name="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="loginForm.username"
                  name="username"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="loginForm.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-overlay :show="isLogginIn">
              <b-button
                variant="primary"
                type="submit"
                block
                :disabled="invalid"
              >
                Login
              </b-button>
            </b-overlay>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay,
  BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    VuexyLogo,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      // validation rules
      required,
      email,

      loginForm: {
        username: '',
        password: '',
      },
    }
  },
  computed: {
    ...mapGetters(['loggedIn']),
    ...mapState({
      isLogginIn: (state) => state.auth.isLoggingIn,
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    loggedIn(val){
      if(val === true){
         this.$router.replace('/')
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Welcome',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'You have successfully logged in. Now you can start to explore!',
              },
            })
      });
      }
    },
  },
  methods: {
    ...mapActions(['login']),
    onSubmit() {
      this.login(this.loginForm)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
